@import "~bootstrap/scss/bootstrap";

:root {
  --radius: 12vmin;
  --frame-size: calc(var(--radius) / 3);
  --d-outer: calc(var(--radius) * 2);
  --d-inner: calc(var(--d-outer) - var(--frame-size));
  --font-size: calc(var(--radius) / 10);
  --default-font-color: #1d1d1b;
  --bs-card-color: trasparent;
  --bs-nav-link-font-size: 0.8125rem;
}

/* Fonts */
/* Zilla Slab */
@font-face {
  font-family: "Zilla Slab";
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CVHaZV3B3X6_0f1Nk.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Zilla Slab";
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CVHaZWXB3X6_0fw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYpEY6H2pW3pwfa3Efg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYpEY6HOpW3pwfa0.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/*sf pro */
@font-face {
  font-family: "sf pro light";
  font-style: normal;
  font-weight: 100;
  src: local("SF Pro light"),
    // url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYREGULAR.woff)
    url(../fonts/sf-pro-text-light.woff) format("woff");
}

@font-face {
  font-family: "sf pro";
  font-style: normal;
  font-weight: 400;
  src: local("sf pro"),
    // url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYREGULAR.woff)
    url(../fonts/sf-pro-text-regular.woff) format("woff");
}

@font-face {
  font-family: "sf pro";
  font-style: italic;
  font-weight: 100;
  src: local("sf pro"),
    url(../fonts/sf-pro-text-regularItalic.woff) format("woff");
}

@font-face {
  font-family: "sf pro";
  font-style: italic;
  font-weight: 200;
  src: local("sf pro"),
    url(../fonts/sf-pro-text-regularItalic.woff) format("woff");
}

@font-face {
  font-family: "sf pro";
  font-style: italic;
  font-weight: 300;
  src: local("sf pro"),
    url(../fonts/sf-pro-text-regularItalic.woff) format("woff");
}

@font-face {
  font-family: "sf pro";
  font-style: normal;
  font-weight: 500;
  src: local("sf pro"), url(../fonts/sf-pro-text-regular.woff) format("woff");
}

@font-face {
  font-family: "sf pro";
  font-style: italic;
  font-weight: 600;
  src: local("sf pro"),
    url(../fonts/sf-pro-text-regularItalic.woff) format("woff");
}

@font-face {
  font-family: "sf pro";
  font-style: normal;
  font-weight: 700;
  src: local("sf pro"), url(../fonts/sf-pro-text-semibold.woff) format("woff");
}

@font-face {
  font-family: "sf pro";
  font-style: italic;
  font-weight: 800;
  src: local("sf pro"), url(../fonts/SF-Pro-Text-BoldItalic.woff) format("woff");
}

@font-face {
  font-family: "sf pro";
  font-style: italic;
  font-weight: 900;
  src: local("sf pro"), url(../fonts/SF-Pro-Text-BoldItalic.woff) format("woff");
}

@font-face {
  font-family: "TrashHand";
  src: url("../fonts/TrashHand.ttf.woff") format("woff"),
    url("../fonts/TrashHand.ttf.svg#TrashHand") format("svg"),
    url("../fonts/TrashHand.ttf.eot"),
    url("../fonts/TrashHand.ttf.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

/* Colors */
.puntaAltaOrange {
  color: #fe5000;
}

body {
  // overflow-y: scroll;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch;
  // background-color: #e6e6e6;
  margin: 0;
  font-family: "sf pro", sans-serif;
  letter-spacing: calc(-50 / 1000) * 1em;
  background: url(../images/aLogo.png) no-repeat center -220px,
    linear-gradient(#fff 0%, #e7e7e7 74%, #ddd 100%);
  background-attachment: fixed;
  min-height: 75vw;
  color: --default-font-color;
}
html,
body {
  overflow-x: hidden !important;
  width: 100vw !important;
}

p,
ul,
li {
  font-family: "Zilla Slab", sans-serif;
  color: --default-font-color;
}

// ul.nav-pills-primary-soft:hover a {
//   color: #ccc;
// }
// ul.nav-pills-primary-soft:hover a:hover {
//   color: #1d1d1b !important;
// }

.light {
  font-family: "sf pro light", sans-serif;
  font-weight: 100;
  & i {
    font-family: "sf pro", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
}

.navbar-toggler span {
  display: block;
  background-color: #4f4f4f;
  height: 3px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}

.rrssIcons {
  height: 18px !important;
}

.rrssIcons:hover {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.logoTop {
  height: 17px !important;
}

.parallax-container {
  display: flex;
  min-height: 72vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /*  this is where the magic happens:  */
  background-attachment: fixed;

  background-position: center -120px;
  background-size: contain;
  background-repeat: no-repeat;
  color: var(--color);
}

h1 {
  font-family: "sf pro", sans-serif;
}
h1 span {
  font-weight: 300;
  font-size: 6.2rem;
  text-align: left;
  line-height: 0.2rem;
  letter-spacing: calc(-50 / 1000) * 1em;
}

h1 > br {
  line-height: 0.1rem;
}

.h1 {
  font-family: "sf pro", sans-serif;
  font-weight: 500;
  font-size: 6.5rem;
  color: --default-font-color;
}

header {
  background: transparent;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  // background-color: #e6e6e6;
  border: 0px solid transparent;
}

.altaGrey {
  background-color: #e6e6e6;
}
.altaColorGrey {
  color: #8d8d8d;
}

.preFootLinks {
  color: --default-font-color;
  text-decoration: none;
  padding-bottom: 10px;
  border-bottom: 1px solid --default-font-color;
  font-size: 1.85rem;
  letter-spacing: calc(-10 / 1000) * 1em;
}
a.preFootLinks:hover {
  cursor: pointer;
  color: #1d1d1b;
  border: 2px solid #0000;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.underline {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border: 2px solid transparent;
  text-decoration: none;
  color: #1d1d1b;
}
.underline:hover {
  color: #1d1d1b;
}
.underline::after {
  border-bottom: 2px solid #1d1d1b;
  width: 0%;
  height: 10px;
  display: block;
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transition: left 0.2s cubic-bezier(0.215, 0.61, 0.355, 1),
    width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.underline:hover::after {
  width: 100%;
  left: 0;
  color: #1d1d1b;
}

footer {
  width: 100%;
  height: 500px;
  bottom: -300px;
  //   position: fixed;
  z-index: -3;
  // background-image: linear-gradient(to bottom, #e6e6e6, #ff5001);
  background-image: linear-gradient(
    to bottom,
    rgb(233, 239, 238) 0.25%,
    rgba(254, 80, 0, 1) 60%,
    #fe5000 100%
  );
  color: #ffffff;
  overflow: hidden;
}

footer a,
footer p {
  font-family: "sf pro", sans-serif;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  position: relative;
}

footer .privacidad a,
footer .privacidad {
  font-size: 9px;
}

footer a {
  background: linear-gradient(0deg, white, white) no-repeat right bottom / 0
    var(--bg-h);
  transition: background-size 350ms;
  --bg-h: 100%;
  padding-bottom: 2px;
  --bg-h: 2px;
}
footer a.activeFooterLink {
  background: linear-gradient(0deg, white, white) no-repeat right bottom / 0
    var(--bg-h);
  background-size: 100% var(--bg-h);
  background-position-x: left;

  transition: background-size 350ms;
  --bg-h: 100%;
  padding-bottom: 2px;
  --bg-h: 2px;
}
footer a:hover {
  color: #fff;
  text-decoration: none;
}

footer a:where(:hover, :focus-visible) {
  background-size: 100% var(--bg-h);
  background-position-x: left;
}

footer h1 {
  line-height: 300px;
  background-image: linear-gradient(to bottom, #e6e6e6, #ff5001);
}

footer .row {
  position: relative;
  bottom: -10rem;
}

header,
footer,
#scroll-animate-main {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  transition-property: all;

  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  transition-duration: 0.4s;

  -webkit-transition-timing-function: cubic-bezier(0, 0, 0, 1);
  -moz-transition-timing-function: cubic-bezier(0, 0, 0, 1);
  transition-timing-function: cubic-bezier(0, 0, 0, 1);
}

.wrapper-parallax {
  margin-top: 100%;
  margin-bottom: 300px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
}

.navbar-expand-md .navbar-brand .navbar-brand-item {
  height: auto;
}

.textoMainHome {
  text-align: left;
  // margin-left: 2rem;
}

/*cirble animation */
.circle-f-lottie {
  position: absolute;
  left: 47vw;
  top: 20.3vw;
  z-index: 3;
  width: 4.7vw;
  -webkit-transform: rotate(4deg) translate(0px, 60%);
  -ms-transform: rotate(4deg) translate(0px, 60%);
  transform: rotate(4deg) translate(0px, 60%);
}

.circle-f-lottie path,
.line-f-lottie-holder path {
  fill: #fe5000;
}

.line-f-lottie-holder {
  /* right: 12vw; */
  left: 43vw;
  top: 18rem;
  width: 18vw;
  -webkit-transform: translate(50%, 0px);
  -ms-transform: translate(50%, 0px);
  transform: translate(50%, 0px);
  position: absolute;
  //   left: 56.5vw;
  /* right: 12vw; */
  //   bottom: -2.5rem;
  z-index: 3;
  //   width: 11rem;
  -webkit-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg);
}

.signature {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 0;
  padding-bottom: 55.30973%;
}

.signature svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

path {
  stroke-dashoffset: 600;
  stroke-dasharray: 600;
  animation: draw 1s linear forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 1;
  }
}

.textoRevisamos {
  font-size: 0; /* Hacemos desaparecer el texto */
  animation: aparecer 2s linear forwards;
  animation-delay: 1s;
}

@keyframes aparecer {
  0% {
    font-size: 0;
  }
  100% {
    font-size: 26.87px;
  }
}

/* expansive buttons and links */

button,
a.whatsBtn,
a.verProyecto,
a.volverProyecto {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
button.learn-more {
  width: 18rem;
  height: auto;
  font-family: "sf pro";
}

button.verProyecto,
button.verProyecto,
a.whatsBtn,
a.verProyecto {
  width: 11rem;
  height: auto;
  font-family: "sf pro";
}

button#conoceMas,
a#conoceMas,
a.verProyecto.grupoAlta {
  width: 18.5rem;
  height: auto;
}

button.learn-more .circle,
button.verProyecto .circle,
a.whatsBtn .circle,
a.verProyecto .circle,
a.volverProyecto .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.1rem;
  height: 2.1rem;
  background: #ff5001;
  border-radius: 1.625rem;
}
button.learn-more .circle .icon,
button.verProyecto .circle .icon,
a.whatsBtn .circle .icon,
a.volverProyecto .circle .icon,
a.verProyecto .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
button.learn-more .circle .icon.arrow,
button.verProyecto .circle .icon.arrow,
a.whatsBtn .circle .icon.arrow,
a.verProyecto .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
button.learn-more .circle .icon.arrow:before,
button.verProyecto .circle .icon.arrow:before,
a.whatsBtn .circle .icon.arrow:before,
a.volverProyecto .circle .icon.arrow:before,
a.verProyecto .circle .icon.arrow:before {
  position: absolute;
  content: "";
  width: 2rem;
  height: 2rem;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  position: absolute;
  background-image: url("../images/conFlecha.svg");
  top: -0.95rem;
  right: -0.2rem;
}

button.learn-more .button-text,
button.verProyecto .button-text,
a.whatsBtn .button-text,
a.volverProyecto .button-text,
a.verProyecto .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0.3rem;
  margin: -8px 0 0 1.8rem;
  color: #ff5001;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  letter-spacing: normal;
  //   text-transform: uppercase;
}

a.volverProyecto .circle .icon.arrow:before,
a.volverProyecto .circle .icon.arrow:before {
  content: "";
  width: 2rem;
  height: 2rem;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  position: absolute;
  background-image: url("../images/conFlecha.svg");
  top: 0;
  right: -2rem;
  transform: rotate(180deg);
}

a.volverProyecto {
  width: 12rem;
  height: auto;
  font-family: "sf pro";
}

a.volverProyecto .button-text {
  margin: -8px 0 0 1.85rem;

  & :hover {
    color: #fff;
  }
}
a.volverProyecto:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(-2rem, 10rem);
}
a.volverProyecto:hover .circle .icon.arrow:before {
  right: -1.5rem;
}

button:hover .circle,
a.volverProyecto:hover .circle,
a.verProyecto:hover .circle,
a:hover .circle {
  width: 100%;
}
button:hover .circle .icon.arrow,
a.volverProyecto:hover .circle .icon.arrow,
a.verProyecto:hover .circle .icon.arrow,
a:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}
button:hover .button-text,
a:hover .button-text {
  color: #fff !important;
}

/* expansive buttons and links */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.noDisplay {
  display: none;
}

.bounce2 {
  animation-delay: 15s;
  animation: bounce2 8s ease infinite;
}

@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.logosFooter a path {
  stroke: #ffffff !important;
  fill: #ffffff !important;
  color: #ffffff !important;
}

.logosFooter a svg {
  display: inline-block;
  vertical-align: middle;
  fill: #ffffff !important;
  color: #ffffff !important;
}
.logosFooter a span {
  display: inline-block;
  vertical-align: middle;
  padding-top: 2px;
  fill: #ffffff !important;
}

#flecha {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: absolute;
}

.draw-arrow {
  stroke-width: 5;
  stroke: #000;
  fill: none;
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation-duration: 2s;
  animation-delay: 5s;
  animation-fill-mode: forwards;
  animation-name: drawFlecha;
  &.tail-1 {
    animation-delay: 5.5s;
  }
  &.tail-2 {
    animation-delay: 5.7s;
  }
}

@keyframes drawFlecha {
  to {
    stroke-dashoffset: 0;
  }
}
#flecha {
  transform: rotate(270deg);
  transform-origin: center;
  position: relative;
  top: 0px;
  left: -228px;
}

.rallaAbajo svg {
  width: 100%;
  height: 100%;
  transform: scale(2.5) translate3d(-1%, 126%, 0);
}

/* ASPID LOGO */
.circle {
  position: relative;
  width: var(--d-outer);
  height: var(--d-outer);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  position: absolute;
  width: var(--d-inner);
  height: var(--d-inner);
  //   width: var(--d-inner);
  //   height: var(--d-inner);
  background: url(../images/logoAspid.png);
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  filter: contrast(1.5);
}
.logo2 {
  position: absolute;
  // width: var(--d-inner);
  // height: var(--d-inner);
  //   width: var(--d-inner);
  //   height: var(--d-inner);
  background: url(../images/logoAspid.png);
  // background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  filter: contrast(1.5);
}
.textLogo {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: rotateText 10s linear infinite;
}
@keyframes rotateText {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.textLogo p span {
  position: absolute;
  left: 50%;
  font-size: 1.2em;
  transform-origin: 0 var(--radius);
}

.textoCentral {
  font-size: 4rem;
  font-weight: 100;
  text-align: center;
}

.lineaFinal {
  top: -20rem;
  left: 10rem;
  position: relative;
  width: 120%;
}

.circleFinal {
  // position: relative;
  // left: -2rem;
  // top: -16rem;
  // z-index: 5;
  // width: 19.7vw;
  z-index: 0;
  /* width: 3.7vw; */
  top: -3.5rem;
  left: -1rem;
}

.card {
  background-color: transparent;
  border: 0px;
}
.rrssLinks {
  list-style: none;
  display: flex;
}

/* circle around link */

.nav-link {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.nav-link a {
  padding: 8px 16px;
  border: none;
  background: none;
  outline: none;
}

.saludableCricleA svg,
.nav-link svg,
.evidenciaAnimada svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.saludableCricleA path,
.nav-link path,
.evidenciaAnimada path {
  stroke: #db3157;
  stroke-width: 8px;
  stroke-dasharray: 0 1900;
  // stroke-dasharray: 1210.709716796875;
  // stroke-dashoffset: -1210.709716796875;
}

.nav-link path {
  stroke: #23282e;
}
.saludableCricleAnimation path,
.nav-link:hover path,
.evidenciaAnimacion:hover path {
  animation: draw2 1s forwards;
  // transition: stroke-dashoffset 0.5s cubic-bezier(0.29, 0.68, 0.74, 1.02);
}

@keyframes draw2 {
  from {
    stroke-dasharray: 0 1900;
  }

  to {
    stroke-dasharray: 1900 1900;
  }
}

.nav-link svg.queHacemos {
  position: absolute;
  top: 2rem;
  left: 0.5rem;
  width: 90%;
  height: 20%;
  pointer-events: none;
}

.nav-link svg.quienesSomos {
  position: absolute;
  top: 1.8rem;
  left: 0.5rem;
  width: 90%;
  height: 30%;
  pointer-events: none;
}

.nav-link svg.contactoCircle {
  top: -0.6rem;
  left: -2.5rem;
  height: 3rem;
  width: 170%;
  pointer-events: none;
}
.nav-link svg.contactoCircle path {
  stroke-width: 3px;
  // fill: #23282e;
}

.nav-link svg.queHacemos path,
.nav-link svg.quienesSomos path {
  // stroke: #ff5001;
  stroke: #23282e;
  stroke-width: 7px;
  stroke-dasharray: 0 1300;
  // stroke-dasharray: 1210.709716796875;
  // stroke-dashoffset: -1210.709716796875;
}
.nav-link:hover svg.queHacemos path,
.nav-link:hover svg.quienesSomos path,
.nav-link:hover svg.contactoCircle path {
  animation: draw3 1s forwards;
  // transition: stroke-dashoffset 0.5s cubic-bezier(0.29, 0.68, 0.74, 1.02);
}

@keyframes draw3 {
  from {
    fill: transparent;
  }

  to {
    fill: #23282e;
  }
}

/* ASPID LOGO */
.aspidLogo {
  position: relative;
  align-items: center;
  text-align: center;
}

#elLogo {
  position: absolute;
  top: 35%;
  left: 23%;
  z-index: 1;
}

.aspidLogo img#circlePremio {
  width: 150px;
  height: 150px;
  animation-name: rotate;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-radius: 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.contraLogo {
  position: absolute;
  bottom: -250px;
  right: -10vw;
  height: 279px !important;
}

/* fading line */
.top-to-bottom {
  border-width: 1px 1px 1px 0;
  border-style: solid;
  -webkit-border-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(black),
      to(rgba(0, 0, 0, 0))
    )
    1 100%;
  -webkit-border-image: -webkit-linear-gradient(black, rgba(0, 0, 0, 0)) 1 100%;
  -moz-border-image: -moz-linear-gradient(black, rgba(0, 0, 0, 0)) 1 100%;
  -o-border-image: -o-linear-gradient(black, rgba(0, 0, 0, 0)) 1 100%;
  border-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0)) 1 100%;
  border-left: 0px;
}

/* Accordion */
.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bopacity:0.53;%7D.cls-2%7Bfill:none;stroke:%233c3c3b;stroke-miterlimit:10;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='contenido'%3E%3Cg class='cls-1'%3E%3Cpath class='cls-2' d='M1,0A4.37,4.37,0,0,0,5.37,4.37'/%3E%3Cpath class='cls-2' d='M1,8.74A4.37,4.37,0,0,1,5.37,4.37'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%231d1d1b;stroke-miterlimit:10;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='contenido'%3E%3Cpath class='cls-1' d='M8.74,1A4.37,4.37,0,0,0,4.37,5.37'/%3E%3Cpath class='cls-1' d='M0,1A4.37,4.37,0,0,1,4.37,5.37'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  --bs-accordion-btn-icon-transform: rotate(0deg);
  --bs-accordion-btn-color: #0000008c;
  // --bs-accordion-color: #0000008c;
  --bs-accordion-active-color: #212529;
  --bs-accordion-border-color: transparent;
  --bs-accordion-active-bg: transparent;
  --bs-accordion-btn-bg: transparent;
}

/*tickets animation */

* {
  box-sizing: border-box;
}
$duration: 195s;
$duration2: 195s;

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  // position: fixed;
  // bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 4rem;
  // background-color: rgba(#000, 0.9);
  background-color: transparent;
  padding-left: 100%;
  box-sizing: content-box;

  .ticker,
  .tickerLogos {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: $duration;
    animation-duration: $duration;

    &__item {
      display: inline-block;
      font-family: "sf pro light", sans-serif;
      letter-spacing: calc(400 / 1000) * 1em;
      padding: 0 2rem;
      font-size: 1.2rem;
      color: #1d1d1b;
    }
  }
}

.tickerLogos {
  -webkit-animation-duration: $duration2 !important;
  animation-duration: $duration2 !important;
}

.nav-item a {
  letter-spacing: 0.2em;
  font-weight: 500;
}
/* active link underline with svg */
.activeNavLink {
  position: relative;
}

#quehacemos .activeNavLink::after {
  background-image: url("../images/queHacemosSVG.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.activeNavLink::after {
  content: "";
  position: absolute;
  bottom: -0.35rem;
  left: -0.5rem;
  right: -0.5rem;
  height: 0.75rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  z-index: -1;

  background-image: url("../images/activeLink.svg");
  background-repeat: no-repeat;

  background-size: contain;
}
/* (1440x900) WXGA+ Display */
@media screen and (min-width: 1300px) and (max-width: 1580px) {
  .line-f-lottie-holder {
    left: 51vw;
    top: 15.5rem;
    width: 21vw;
  }
  .circle-f-lottie {
    position: relative;
    left: 13.5rem;
    top: 3.5rem;
    z-index: 3;
    width: 5vw;
  }

  .healthAddicts {
    // display: flex;
    margin-top: 2.5rem;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .parallax-container,
  body {
    min-height: 100vh !important;
    margin-top: 5vh;
  }

  h1 > span {
    margin-top: 4rem;
    font-size: 5.5rem;
  }

  h1 > br {
    line-height: 0.1rem;
  }

  .textoMainHome {
    text-align: left;
    margin-left: 0rem;
  }

  #whatsPopover {
    display: none;
  }

  .line-f-lottie-holder {
    left: 9vw;
    top: 28.5rem;
    width: 76vw;
  }

  .circle-f-lottie {
    position: absolute;
    left: 62vw;
    top: 143.7vw;
    z-index: 3;
    width: 20.7vw;
  }

  .rallaAbajo svg {
    width: 100%;
    height: 100%;
    transform: scale(2) translate3d(-1%, 126%, 0);
  }

  .textoCentral {
    font-size: 2.3rem;
  }

  .lineaFinal {
    top: -18rem;
    left: 12rem;
    width: 50%;
  }

  figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  figure:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  figure1 img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  figure1:hover img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  figure2 img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  figure2:hover img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  figure3 img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  figure3:hover img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  figure4 img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  figure4:hover img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  figure5 img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  figure5:hover img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  figure6 img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  figure6:hover img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  .card-body {
    padding: 0.1rem 0.5rem;
  }

  footer {
    height: 565px;
    bottom: -300px;
    padding-left: 20px;
  }

  footer {
    width: 100%;
    z-index: -3;
    color: #fff;
    background-image: linear-gradient(#e9efee 0.5%, #fe5000 20% 100%);
    bottom: -300px;
  }

  footer a,
  footer p {
    font-size: 13px;
  }

  footer .row {
    position: relative;
    bottom: -4rem;
  }

  .introProjects {
    margin: 0;
    font-family: "sf pro", sans-serif;
    min-height: 20vw;
    // background: url(../images/aLogoCenter.png) no-repeat center center, #e6e6e6;
    background: linear-gradient(
        90deg,
        rgb(224, 232, 231) 11.4431%,
        rgb(255, 255, 255) 81.7326%
      ),
      url(../images/aLogo.png);
    z-index: 1;
    background-attachment: fixed;
  }
  .introProjects h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .navbar-nav {
    min-height: 100vh;
    position: relative;
  }

  .nav-item a {
    padding-top: 1.4rem;
    color: #494949;
  }

  #quehacemos .nav-item a {
    padding-top: 1.6rem;
    color: #494949;
  }

  .rrssLinks {
    position: absolute;
    top: 65vh;
    padding: 0;
    list-style: none;
    display: inline-flex;
  }
  .rrssLinks li {
    margin-right: 3rem;
  }

  button:focus-visible {
    outline: 4px dashed black;
  }
  button:focus:not(:focus-visible) {
    outline: none;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .navbar-toggler span {
    background-color: #fd5204;
  }

  .icon-bar,
  .navbar-toggler span {
    background-color: #ff5001 !important;
  }
  .navbar-toggler span {
    display: block;
    background-color: #4f4f4f;
    height: 3px;
    width: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;
    opacity: 1;
    transition: all 0.35s ease-out;
    transform-origin: center left;
  }
  .navbar-toggler span:nth-child(1) {
    transform: translate(0%, 0%) rotate(0deg);
  }
  .navbar-toggler span:nth-child(2) {
    opacity: 1;
  }
  .navbar-toggler span:nth-child(3) {
    transform: translate(0%, 0%) rotate(0deg);
  }
  .navbar-toggler span:nth-child(1) {
    margin-top: 0.3em;
  }
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    transform: translate(15%, -33%) rotate(45deg);
  }
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    opacity: 0;
  }
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    transform: translate(15%, 33%) rotate(-45deg);
  }
  .bounce2 {
    height: 5rem;
  }
}

/* (1680x1050) WSXGA+ Display */
// @media screen and (max-width: 1680px) {
//   .circle-f-lottie {
//     position: absolute;
//     left: 49.5vw;
//     top: 23.6vw;
//     //   position: relative;
//     //   left: 17.8vw;
//     //   top: -4.6vw;
//     z-index: 3;
//     width: 4.7vw;
//   }
//   .line-f-lottie-holder {
//     position: relative;
//     left: 35.9vw;
//     bottom: -1rem;
//     top: 20rem;
//   }
// }

/* (1920x1080) Full HD Display */
@media screen and (min-width: 1900px) {
  .line-f-lottie-holder {
    position: relative !important;
    left: 11vw !important;
    // bottom: -3rem;
    top: -1.4rem !important;
  }
  .circle-f-lottie {
    position: relative;
    left: 12.8rem;
    top: 3.4rem;
    z-index: 3;
    width: 4.7vw;
  }

  #flecha {
    transform: rotate(270deg);
    transform-origin: center;
    position: relative;
    top: 53px;
    left: -470px;
  }
}

/* (1600x900) HD+ Display */
@media screen and (min-width: 1590px) and (max-width: 1890px) {
  .line-f-lottie-holder {
    position: relative !important;
    left: 16vw !important;
    // bottom: -3rem;
    top: -1.4rem !important;
  }
  .circle-f-lottie {
    position: relative;
    left: 13.2vw;
    top: 3.5vw;
    z-index: 3;
    width: 4.7vw;
  }
  .contenedorTextoCentral h3 {
    font-size: 1.5rem;
  }
}

/* Hide Collapsed menus on movile */
@media only screen and (max-width: 768px) {
  .hidden-mobile {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  #whatsOpener {
    display: none;
  }
}

@media only screen and (min-width: 546px) and (max-width: 820px) {
  a.nav-link {
    font-size: 0.6rem;
  }
}
